
  import { onMounted, defineComponent, reactive } from 'vue'
  import { ElMessage } from 'element-plus'
  import {useRouter} from 'vue-router'
  import api from '@/api/index'
  export default defineComponent({
    setup() {
      const router = useRouter()
      const industryList:any = reactive([])
      const examineData = reactive({
        id:0,
        show:false,
        status:"1",
        reason:''
      })
      const searchData = reactive({
        addtime:[],
        starttime:'',
        endtime:'',
        industry:'',
        words:'',
        limit:10,
        page:1,
        status:'-1',
      })
      const tableData = reactive({list:[],total:0,current_page:1})
      const goBack = () => {
        router.go(-1)
      }
      const changetime = () => {
        if(searchData.addtime){
          searchData.starttime = searchData.addtime[0]
          searchData.endtime = searchData.addtime[1]
        }else{
          searchData.starttime = ''
          searchData.endtime = ''
        }
      }
      const getIndustry = () => {
        api.memberApi.getIndustry().then((res:any)=>{
          res.data.filter((item:string) => {
            industryList.push({value:item,label:item})
          })
        }).catch((res:any) => {
          ElMessage.error(res.message);
        })
      }
      const edit = (id:number) => {
        router.push({path:'/member/detail/'+id})
      }
      const showExamine = (id:number) => {
        examineData.show = true
        examineData.status = "1"
        examineData.reason = ''
        examineData.id = id
      }
      const getList = () => {
        api.memberApi.getList(searchData).then((res:any) => {
            if (res.code === 0) {
              searchData.limit = res.data.per_page
              tableData.list = res.data.data
              tableData.total = res.data.total
              tableData.current_page = res.data.current_page
            }else{
              ElMessage.error(res.message)
            }
        }).catch((res:any) => {
            ElMessage.error(res.message);
        })
      }
      const setexamine = () =>{
        api.memberApi.setexamine(examineData).then((res:any) => {
            if (res.code === 0) {
              ElMessage({
                message: res.message,
                type: 'success',
              })
              examineData.show = false
              getList()
            }else{
              ElMessage.error(res.message)
            }
        }).catch((res:any) => {
            ElMessage.error(res.message);
        })
      }
      const handleCurrentChange = (val:number) => {
        searchData.page = val
        getList()
      }
      const del = (id:number) => {
        api.memberApi.del({id:id}).then((res:any) => {
            if (res.code === 0) {
              ElMessage({
                message: res.message,
                type: 'success',
              })

              getList()
            }else{
              ElMessage.error(res.message)
            }
        }).catch((res:any) => {
            ElMessage.error(res.message);
        })
      }
      const sreach = () => {
        searchData.page = 1
        getList()
      }
      onMounted(()=>{
        getList()
        getIndustry()
      })
      return {
        examineData,
        searchData,
        tableData,
        industryList,
        getList,
        goBack,
        edit,
        handleCurrentChange,
        del,
        sreach,
        showExamine,
        setexamine,
        getIndustry,
        changetime
      }
    }
  })
  
  
  
  
  
  
